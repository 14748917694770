import React, { useEffect } from 'react'
import Express from '../src/Assests/express.png';
import Ekart from '../src/Assests/eKartlogo1.png';
import Delhivery from '../src//Assests/delhivery-tracking-logo 1.png';
import Dtdc from '../src/Assests/dtdclogo1.png';
import SafeExpress from '../src/Assests/safexpresslogo1.png';
import './OurPartners.css';

// import Aos from "aos";
// import 'aos/dist/aos.css';

const OurPartners = () => {

    // useEffect(()=>{
    //     Aos.init({duration:2000});
    // },[]);

  return (
    // with animations:
    // <section class = 'section1' data-aos="fade-down-right">
    //     <div class='items' data-aos="slide-right">
    //         <ul data-aos="zoom-out">
    //             <li class='image1' data-aos="zoom-out">
    //                 <img src = {Express}></img>
    //             </li>
    //             <li class='image2' data-aos="zoom-out">
    //                 <img src={Ekart}></img>
    //             </li>
    //             <li class='image3' data-aos="fade-up">
    //                 <img src={Delhivery}></img>
    //             </li>
    //             <li class='image4' data-aos="fade-up">
    //                 <img src={Dtdc}></img>
    //             </li>
    //             <li class='image5' data-aos="zoom-out">
    //                 <img src={SafeExpress}></img>
    //             </li>
    //             <li class='image1' data-aos="zoom-out">
    //                 <img src = {Express}></img>
    //             </li>
    //             <li class='image2' data-aos="zoom-out">
    //                 <img src={Ekart}></img>
    //             </li>
    //             <li class='image3' data-aos="fade-up">
    //                 <img src={Delhivery}></img>
    //             </li>
    //             <li class='image4' data-aos="fade-up">
    //                 <img src={Dtdc}></img>
    //             </li>
    //             <li class='image5' data-aos="zoom-out">
    //                 <img src={SafeExpress}></img>
    //             </li>
    //         </ul>
    //     </div>



    <section class = 'section1' >
        <div class='items'>
            <ul>
                <li class='image1'>
                    <img src = {Express}></img>
                </li>
                <li class='image2' >
                    <img src={Ekart}></img>
                </li>
                <li class='image3'>
                    <img src={Delhivery}></img>
                </li>
                <li class='image4' >
                    <img src={Dtdc}></img>
                </li>
                <li class='image5'>
                    <img src={SafeExpress}></img>
                </li>
                <li class='image1' >
                    <img src = {Express}></img>
                </li>
                <li class='image2'>
                    <img src={Ekart}></img>
                </li>
                <li class='image3' >
                    <img src={Delhivery}></img>
                </li>
                <li class='image4' >
                    <img src={Dtdc}></img>
                </li>
                <li class='image5' >
                    <img src={SafeExpress}></img>
                </li>
            </ul>
        </div>

    </section>
  )
}

export default OurPartners