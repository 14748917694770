import React from "react";
import './LatestNews.css';
import LatesNewsBody1 from "./LatestNewsBody1";
import LatesNewsBody2 from "./LatestNewsBody2";
import LatesNewsBody3 from "./LatestNewsBody3";
function LatestNews()
{
    return(
        <div className="latesNews">
            <div className="LatesNewsName">
            Latest News
            </div>
            <div className="LatestContent">
            <LatesNewsBody1/>
            <LatesNewsBody2/>
            <LatesNewsBody3/>
            </div>
            
            
        </div>
    );
}

export default LatestNews;