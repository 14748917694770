import Image11 from "../src/Assests/lorry.png";
import Image22 from "../src/Assests/lorry-2.png";
import Image33 from "../src/Assests/lorry-3.png";


export const SliderData=[
    {
        image: Image11,
        heading : "Fast and Safe Delivery",
    },
    {
        image : Image22,
        heading : "From Anywhere to Everywhere",
    },
    {
        image : Image33,
        heading : "Fast and Safe Delivery",
    },
]