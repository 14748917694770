// import react from 'react'
// import './AboutUs1.css'

// function AboutUs1 () {
//     <div className='NewServices'>
//       <div className='headline'>
//         <p>Our Services</p>
//       </div>

//       <div className='boxes'>
//             <div className='box'> 
//                 <p> Express deliveries</p>
//             </div>
//             <div className='box'> 
//                 <p> Fulll truck load service</p>
//             </div>
//             <div className='box'> 
//                 <p> Less than truck load service</p>
//             </div>
//             <div className='box'> 
//                 <p> Temprature controlled transport</p>
//             </div>
//         </div>

//     </div>

// }
// export default AboutUs1;

import React, { useEffect } from 'react';
import './AboutUs1.css';
import Image1 from './Assests/Image1.png'


function AboutUs1()     // Services section on home pg
{

 return (
    <section id="aboutus1" className='white'>
        
        <div className='Wrap1 flex-container'>
          <div className='col-1'> 
            <div className="headBox">
                <h2 className='headline'>OUR SERVICES</h2>
            </div>
         </div>
        
            <div className='boxes'>
            <div className='box'> 
                <p className='services-list'> Express deliveries</p>
            </div>

            <div className='box'> 
            <p className='services-list'> Fulll truck load service</p>
            </div>

            <div className='box'> 
                <p className='services-list'> Less than truck load service</p>
            </div>

            <div className='box'> 
                <p className='services-list'> Temprature controlled transport</p>
            </div>
            </div>

        
            <div className='imagedecor'>
                <img src={Image1}></img>

                <div className='button2'>
                <a href="#" className='text'>Know More</a>
                </div>
            </div>


        </div>

    </section>
  )
}
export default AboutUs1;