import React, { useEffect } from "react";
import './Home.css';
import AboutUsHome from "./AboutUsHome";
import AboutUs1 from "./AboutUs1";
import StatusPart from "./StatusPart"
import OurPartners from "./OurPartners";
import LatestNews from "./LatestNews";
import Slider from "./HomeDupo";
function Home()
{
    return(
    <>
    <section className="home">
        {/* <div className="homeHeading">
            <span>Fast and Safe delivery</span>
        </div>
        <button>Get Started</button> */}
        
    </section>
    <Slider />
    <AboutUs1/>
    <StatusPart />
    <AboutUsHome/>     
    <OurPartners/>
    <LatestNews />
    </>
    )
}

export default Home;