import {useState, useEffect} from 'react'
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import {SliderData} from './SliderData.js';
import Image11 from  "../src/Assests/lorry.png";
import './HomeDupo.css';


const HomeDupo = () => {
  const[currentSlide, setCurrentSlide] = useState(0);
  const slideLength = SliderData.length;

  const autoScroll = true;
  let slideInterval ;
  let intervalTime = 3000;

   const nextSlide = () => {
      setCurrentSlide(currentSlide === slideLength - 1? 0 :currentSlide + 1);
   };

   const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 :currentSlide - 1);
 };

  function auto(){
      slideInterval = setInterval(nextSlide, intervalTime)
 }


  useEffect(()=> {
    setCurrentSlide(0)
  },[])


  
  useEffect(()=> {
    if(autoScroll){
      auto();
    }
    return () => clearInterval(slideInterval)
  },[currentSlide])

  return (
    <div className='Slider'>
      <AiOutlineArrowLeft className="arrow prev" onClick={prevSlide}/>
      <AiOutlineArrowRight className="arrow next" onClick={nextSlide}/>
      {SliderData.map((slide,index) => {
        return(
          <div className={index === currentSlide ?
            "slide current" : "slide"} key={index}>
              {index === currentSlide && (
                <>
                <img src={slide.image} alt="slide" className='img-header'/>
                <div className='homeHeading'>
                  <span>{slide.heading}</span>
                  </div>
                  <hr />
                  <div className="button-get-started">
                  <button id="botton-get">Get Started</button>
                  </div>
                  </>
              )}
          </div>
        )
      })}
    </div>
  )
}

export default HomeDupo