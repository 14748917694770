
import Logo from '../src/Assests/Logo.png'

import React, { useState } from "react";

import "./NavBar.css";
import { Link, NavLink } from "react-router-dom";

export const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <nav>
      <NavLink to="/">
      <img className='Logo' src={Logo} alt='Logo' style={{marginLeft:'15px'}}></img>
      </NavLink>
      <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li className='brd-btm'>
          <NavLink to="/" onClick={() => window.innerWidth <= 480 && setMenuOpen(false)}>
          Home
          </NavLink>
        </li>

        <li className='brd-btm'>
          <NavLink to="/AboutUs"  onClick={() => window.innerWidth <= 480 && setMenuOpen(false)}>
            AboutUs
          </NavLink>
        </li>

         <li className='brd-btm'>
          <NavLink to="/services"  onClick={() => window.innerWidth <= 480 && setMenuOpen(false)}>
            Services
          </NavLink>
          {/* <Link to='/About'>Services</Link> */}
        </li>

        <li className='brd-btm'>
          <NavLink to="/Gallery"  onClick={() => window.innerWidth <= 480 && setMenuOpen(false)}>
            Gallery
          </NavLink>
        </li>

        <li className='brd-btm'>
          <NavLink to="/ContactUs"  onClick={() => window.innerWidth <= 480 && setMenuOpen(false)}>
            Contact
          </NavLink>
        </li>

        <li className='brd-btm'>
          <NavLink to="/FAQs"  onClick={() => window.innerWidth <= 480 && setMenuOpen(false)}>
            FAQ's
          </NavLink>
        </li>

        <li>
            <NavLink to="/GetQuote"  onClick={() => window.innerWidth <= 480 && setMenuOpen(false)}>
            <span className='button22 white'>Get Quote</span>
          </NavLink>
        </li>
        
      </ul>
    </nav>
  );
};