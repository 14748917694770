import React, { useState } from 'react';
import Header from './FAQsHeader';
import FAQ from './FAQ';
import FAQ2 from './FAQ2';
import './TwoDivs.css';
import './App.css';
import './FAQ.css';

function FAQsMain () {
  
  const [faqs1, setfaqs1] = useState([
    { question: 'What is Gargi Logistics? ',
      answer: 'Gargi Logistics is a leading logistics company based in India, specializing in road freight and logistics services. We are committed to delivering reliable and efficient transportation solutions for businesses across various industries.',
      open: false 
    },
  
    { 
      question: 'What services does Gargi Logistics offer?',
      answer: 'Gargi Logistics focuses on providing road freight and logistics services, including Transportation: We offer a robust fleet of vehicles for the transportation of goods throughout India. Our experienced drivers ensure timely and secure delivery. Full Truckload (FTL): We provide dedicated FTL services for shipments that require an entire trucks capacity. Less Than Truckload (LTL): We offer LTL services for smaller shipments, allowing businesses to share truck space and reduce costs. Last-Mile Delivery: We specialize in efficient last-mile delivery services, ensuring your goods reach their final destination promptly.',
      open: false
    },

    {
      question: 'What are the benefits of choosing Gargi Logistics for road freight services?',
      answer: 'By choosing Gargi Logistics for your road freight needs, you can expect the following benefits: Reliable and On-time Delivery: We prioritize timely delivery to ensure your goods reach their destination as scheduled. Experienced and Trained Drivers: Our drivers are highly skilled professionals who adhere to strict safety standards. Advanced Tracking and Monitoring: We provide real-time tracking and monitoring of your shipments, keeping you informed throughout the transportation process. Competitive Pricing: We offer competitive rates for our road freight services, ensuring cost-effectiveness for your business. Customer Support: Our dedicated customer support team is available to address any queries or concerns you may have.',
      open: false
    },

    {
      question: 'What areas does Gargi Logistics cover for road freight services ',
      answer: 'Gargi Logistics covers a wide network of destinations within India for road freight services. Our comprehensive coverage includes major cities, towns, and industrial hubs across the country.',
      open: false
    },

    {
      question: 'What is the process for handling and resolving any issues or complaints? ',
      answer: 'In the unlikely event of any issues or complaints, Gargi Logistics has a dedicated customer support team that will promptly address your concerns. Please reach out to our team, providing details of the issue, and we will work towards a satisfactory resolution.',
      open: false
    },

    {
      question: 'Does Gargi Logistics offer door-to-door delivery? ',
      answer: 'Yes, Gargi Logistics provides door-to-door delivery services. Our team ensures that your goods are picked up from the designated location and delivered directly to the specified destination, offering convenience and efficiency.',
      open: false
    },

    {
      question: 'Does Gargi Logistics have a customer support team available for assistance?',
      answer: 'Yes, Gargi Logistics has a dedicated customer support team available to assist you with any queries, concerns, or assistance you may require. You can contact our customer support through phone, email, or the designated communication channels provided on our website.',
      open: false
    } 
  ]);

    
  const [faqs2, setfaqs2] = useState([
      {
        question: 'Where is Gargi Logistics located?',
        answer: 'Gargi Logistics is headquartered in [insert location], India. Our extensive network of offices and distribution centers allows us to cover major cities and regions across the country effectively.',
        open: false
      },

      {
        question: 'How can I request a quote or book a shipment with Gargi Logistics?',
        answer: 'To request a quote or book a shipment with Gargi Logistics, you can reach out to our customer support team via phone, email, or through our website. Our representatives will guide you through the process and provide you with the necessary assistance.',
        open: false
      },

      {
        question: 'Is Gargi Logistics compliant with industry regulations and safety standards?',
        answer: 'Yes, Gargi Logistics strictly adheres to industry regulations and safety standards. We prioritize the safety of our customers goods and ensure compliance with all relevant laws and regulations governing road transportation in India.',
        open: false
      },

      {
        question: 'Does Gargi Logistics offer any additional services apart from road freight?',
        answer: 'As a company specializing in road freight and logistics services, Gargi Logistics primarily focuses on providing transportation solutions. However, we can assist in arranging complementary services such as warehousing or customs clearance through our trusted partners upon request.',
        open: false
      },

      {
        question: 'What are Gargi Logistics coverage areas for road freight services?',
        answer: 'Gargi Logistics has a wide coverage area throughout India. We operate across major cities, towns, and remote regions, connecting different states and regions seamlessly.',
        open: false
      },
      
      {
        question: 'How can I request a quote or book services with Gargi Logistics?',
        answer: 'To request a quote or book services with Gargi Logistics, you can reach out to our customer service team via phone, email, or through our website. Our representatives will assist you in understanding your requirements, providing a competitive quote, and guiding you through the booking process.',
        open: false
      }
  ]);
  


  const toggleFAQ = index => {
    setfaqs1(faqs1.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open
      } else {
        faq.open = false;
      }

      return faq;
    }))
  }
    
    
  const toggleFAQ2 = index => {
    setfaqs2(faqs2.map((faq, i) => {
      if (i === index) {
        faq.open = !faq.open
      } else {
        faq.open = false;
      }

      return faq;
    }))
  }

    const TwoDivs = () => {
      return (
      <div className="container1">
        <div className="div1">
          <div className="faqs">       
                    {faqs1.map((faq, i) => (
                    <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
                  ))}
        </div>
        </div>
          
        <div className="div2">
          <div className='faqs'>
            {faqs2.map((faq, i) => (
                <FAQ2 faq={faq} index={i} toggleFAQ={toggleFAQ2} />
                
            ))}
          </div>
        </div> 
      </div> 
      ); 
    };


  const ContactUs = () => {
     return ( 
     <div className='container-fluid'>
        <div className='ContactFAQ'>
          <p> Still got a question? </p>
          <div className='ConUs'>
            <h5 className='p'>Contact Us</h5></div>
        </div>
     </div>
     )
  };



  return (
    <div className="App1">
      <container fluid>
        <Header />           { /* Heading */}
        <TwoDivs/>           { /* Splitting screen */}
        <ContactUs/>         { /* Last part */}
      </container>
    </div>
  );
}

export default FAQsMain;
