import React, { useEffect } from 'react'
import './Circle.css';
import gift from './Components/Blackicon/Gift.png';
import World from './World.png';
import Customer from './Customer.png';

import Aos from "aos";
import 'aos/dist/aos.css';

function Circle() {

  useEffect(()=>{
    Aos.init({duration:2000});
  },[]);

  return (
  <div className='cir' data-aos="fade-up">
      <div className="how" data-aos="zoom-in">
        <h1>How we are different from others?</h1>
      </div>
      <div className="round" data-aos="zoom-in">
    <div className="ro" data-aos="zoom-in">
    <div id="round">
      <img src={World} />
    </div> 
    <p>100% Satisfied Customer</p>
    </div>
    <div className="ro" data-aos="zoom-in">
    <div id="round">
    <img src={gift} />
    </div>
    <p>Quality service </p>
    </div>
    <div className="ro" data-aos="zoom-in">
    <div id="round">
    <img src={Customer} />
    </div>
    <p>Worldwide Locations</p>
    </div>
    

    </div>
</div>
  )
}

export default Circle


