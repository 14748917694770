// import React, { useEffect } from 'react'
// import './ContactUs.css';

// // import Aos from "aos";
// // import 'aos/dist/aos.css';

// const ContactUs = () => {

//   // useEffect(() => {
//   //   Aos.init({duration:2000});
//   // },[]);

//   return (
//     <section className='contactus-header'>
//         <div className="contact-heading" >
//             <h1>CONTACT US</h1>
//         </div>
//         <div className='para-contact-bottom' >
//             <p>For enquiries and feedback, call our Customer Care on: <span className='red-color'>022-65167295</span> or<br />
//             <span className='red-color'> customercare@gargilogistics.com </span></p>
//         </div>
//         <div className='side-heading' >
//             <h1 className='off'>Office Location</h1>
//             <h1 id='side-side-heading'>Head Office</h1>
//             <p id="content-in-box" >1364 NAGAR, Ahmednagar - Daund Rd, Kashti, Maharashtra 414701<br />
//                     Phone : 022-65167295 </p>
//             <div className="line" >  
//             </div>
//             <h1 id='side-side-heading' >Timings</h1>
//             <p id='content-in-box'>Monday to Friday: 09:00 AM To 08:00 PM<br />
//                                     Saturday: 09:00 AM To 07:00 PM<br />
//                                     Sunday: 10:00 AM To 07:00 PM<br /></p>
//         </div>
//     </section>
//   )
// }

// export default ContactUs
















import React from 'react'
import './ContactUs.css';

const ContactUs = () => {
  return (
    <section className='contactus-header'>
        <div className="contact-heading">
            <h1>CONTACT US</h1>
        </div>
        <div className='para-contact-bottom'>
            <p>For enquiries and feedback, call our Customer Care on: <span className='red-color' id="numbers-contact">022-65167295</span><span id="or-in">or</span><br />
            <span className='red-color' id="email-contact"> info@gargilogistics.com </span></p>
        </div>
        <div className='side-heading'>
            <h1 className='off'>Office Location</h1>
            <h1 id='side-side-heading'>Head Office</h1>
            {/* <p id="content-in-box">Shop no:302,v mall, oppsite western express highway, near thakur complex, kandivali east, mumbai 400101</p> */}
            <p id="content-in-box">Shop no:302,v mall, oppsite western express highway, near thakur complex, kandivali east, mumbai 400101<span id='breaked-line'></span><br />
                    Phone : 022-65167295 </p>
            <div className="line">  
            </div>
            <h1 id='side-side-heading'>Timings</h1>
            <p id='content-in-box'>Monday to Friday: 09:00 AM To 08:00 PM<br />
                                    Saturday: 09:00 AM To 07:00 PM<br />
                                    Sunday: 10:00 AM To 07:00 PM<br /></p>
        </div>
    </section>
  )
}

export default ContactUs