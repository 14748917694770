import React from 'react'
import './FAQsindex.css'
function Header () {
	return (
	  <div className='container-fluid'>
		<header>
			<div className='headerFaq'> <h1> FAQ's</h1></div>
        	<div className='headerDesc'> <h5>Got a question? We've got answers </h5></div>
		</header>
		</div>
	)
}

export default Header