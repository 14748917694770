import React  , { useEffect } from 'react'

import Container from './Assests/container.png';
import SmallLogo from './Assests/smalllogo-1.png';
import STeam from './Assests/strongteam.png';
import City from './Assests/networkcities.png';
import Cl from './Assests/happyclients.png';
import './StatusPart.css';

// import Aos from "aos";
// import 'aos/dist/aos.css';

const StatusPart = () => {

    // useEffect(()=>{
    //     Aos.init({duration:2000});
    //     startCounting();
    //   },[]);
    
      // function startCounting() {
      //   let valueDisplay = document.querySelectorAll(".count-status");
      //   valueDisplay.forEach(valueDisplay => {
      //     let startValue = 0;
      //     let endValue = parseInt(valueDisplay.getAttribute("data-value"));
      //     let duration = Math.floor(5000 / endValue); // Update the duration calculation
      //     let counter = setInterval(function () {
      //       startValue += 1;
      //       valueDisplay.textContent = startValue;
      //       if (startValue === endValue) {
      //         clearInterval(counter);
      //       }
      //     }, duration);
      //   });
      // }

  return (
    <section className='section2'>
      <div className='status'>

        <div className='img-container'><img  src={Container}></img></div>
          <h1 className='Heading' >Our Status</h1>

          <div className='logos' >
            <ul className='listlogo'>
              <li className='container-status-logos'>
                <img src={SmallLogo}></img>
                <span className='count-status1' data-value="100">100</span>  {/* change count-status1 to count-status to add animations */}
                <span className='text-logo-status'>Happy shipments</span> 
              </li>
              <li className='container-status-logos'>
                <img src={STeam}></img>
                <span className='count-status1' data-value="10">10+</span>
                <span className='text-logo-status'>Strong Team</span>
              </li>
              <li className='container-status-logos'>
                <img src = {Cl}></img>
                <span className='count-status1' data-value="10">10+</span>
                <span className='text-logo-status'>Happy clients</span>
              </li>
              <li className='container-status-logos'>
                <img src={City}></img>
                <span className='count-status1' data-value="100">100+</span>
                <span className='text-logo-status'>Network cities</span>
              </li>
            </ul>
          </div>
        </div> 
    </section>

  )
}

export default StatusPart