import React, { useEffect} from "react";
import './Footer.css';
import Logo1 from '../src/Assests/Logo1.png'

// import Aos from "aos";
// import 'aos/dist/aos.css'

function Footer()
{

    // useEffect(()=>{
    //     Aos.init({duration:2000});
    // },[]);

    return (
    <div className="footer">
        <div className="footerContent">
            <div className="Logo11">
               <img className='Logo1' src={Logo1}></img>
            </div>
            <div className="footerBody">
                <div className="footerCompany">
                <span>COMPANY</span>
                <ul>
                    <li ><a href="#">Services</a></li>
                    <li ><a href="#">About Us</a></li>
                    <li ><a href="#">Feedback</a></li>
                    <li ><a href="#">News</a></li>
                    <li ><a href="#">FAQs</a></li>
                </ul>  
                </div>
                <div className="footerGetintouch">
                <span>GET IN TOUCH</span>
                <ul>
                    <li><a href="#">Support</a></li>
                    <li><a href="#">Start Shipping</a></li>
                </ul>  
                </div>
                <div className="footerCon" id="footerContact">
                <span>CONTACT</span>
                <ul>
                    {/* <li>1.C-8 Rajdeep Co-Op HSG society, Sec-6, Road no. RSC 52, Charkop, Kandivali West, Mumbai - 400067</li> */}
                    <li>Shop no:302,v mall, oppsite western express highway, near thakur complex, kandivali east, mumbai 400101</li>
                    <li>9594005260</li>
                    <li>gargilogistics@gmail.com</li>
                </ul>
                </div>
                <div className="keeptoDate">
                <span>KEEP ME UPDATED</span>
                <ul>
                <div className="inside">
                    <input id="insideName" placeholder='Email'></input>
                    <button className="cursor-pointer" style={{color:'white'}}>Subscribe</button>
                </div>
                <ul className="footerLogo">
                    <div className="circle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
                        <path d="M16.125 3.375H7.875C5.38972 3.375 3.375 5.38972 3.375 7.875V16.125C3.375 18.6103 5.38972 20.625 7.875 20.625H16.125C18.6103 20.625 20.625 18.6103 20.625 16.125V7.875C20.625 5.38972 18.6103 3.375 16.125 3.375Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.875 8.25C17.4963 8.25 18 7.74632 18 7.125C18 6.50368 17.4963 6 16.875 6C16.2537 6 15.75 6.50368 15.75 7.125C15.75 7.74632 16.2537 8.25 16.875 8.25Z" fill="black"/>
                    </svg>
                    </div>
                    <div className="circle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.75 8.25002H14.25C13.9542 8.24878 13.6611 8.30613 13.3875 8.41876C13.114 8.5314 12.8654 8.69708 12.6563 8.90626C12.4471 9.11544 12.2814 9.36397 12.1688 9.63752C12.0561 9.91106 11.9988 10.2042 12 10.5V21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 13.5H15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                    <div className="circle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19.875 3.375H4.125C3.71079 3.375 3.375 3.71079 3.375 4.125V19.875C3.375 20.2892 3.71079 20.625 4.125 20.625H19.875C20.2892 20.625 20.625 20.2892 20.625 19.875V4.125C20.625 3.71079 20.2892 3.375 19.875 3.375Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.25 10.5V16.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.25 10.5V16.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.25 13.125C11.25 12.4288 11.5266 11.7611 12.0188 11.2688C12.5111 10.7766 13.1788 10.5 13.875 10.5C14.5712 10.5 15.2389 10.7766 15.7312 11.2688C16.2234 11.7611 16.5 12.4288 16.5 13.125V16.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.25 8.625C8.87132 8.625 9.375 8.12132 9.375 7.5C9.375 6.87868 8.87132 6.375 8.25 6.375C7.62868 6.375 7.125 6.87868 7.125 7.5C7.125 8.12132 7.62868 8.625 8.25 8.625Z" fill="black"/>
                    </svg>
                    </div>
                    <div className="circle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 8.2501C12 6.1876 13.7344 4.47197 15.7969 4.5001C16.5192 4.50844 17.2237 4.72521 17.8258 5.12436C18.4278 5.5235 18.9018 6.08804 19.1906 6.7501H22.5L19.4719 9.77822C19.2765 12.82 17.93 15.6732 15.7061 17.7577C13.4823 19.8422 10.5481 21.0016 7.50002 21.0001C4.50002 21.0001 3.75002 19.8751 3.75002 19.8751C3.75002 19.8751 6.75002 18.7501 8.25002 16.5001C8.25002 16.5001 2.25002 13.5001 3.75002 5.2501C3.75002 5.2501 7.50002 9.0001 12 9.7501V8.2501Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                </ul>
                </ul>
                </div>
            </div>
       
            <div className="footerLine">
            </div>

            <div className="copyright">
                © 2023 Gargi Logistics
            </div>
        </div>
    </div>


    );
}

export default Footer;