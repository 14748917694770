import React from "react";
import  ReactDOM from "react-dom";
import App from "./App";
import './styles.css'

ReactDOM.render(
  <div >
    <App />
  </div>
,document.getElementById("root"));
