// import react from 'react'
// import './AboutUs1.css'

// function AboutUs1 () {
//     <div className='NewServices'>
//       <div className='headline'>
//         <p>Our Services</p>
//       </div>

//       <div className='boxes'>
//             <div className='box'> 
//                 <p> Express deliveries</p>
//             </div>
//             <div className='box'> 
//                 <p> Fulll truck load service</p>
//             </div>
//             <div className='box'> 
//                 <p> Less than truck load service</p>
//             </div>
//             <div className='box'> 
//                 <p> Temprature controlled transport</p>
//             </div>
//         </div>

//     </div>

// }
// export default AboutUs1;

import React, { useEffect } from 'react';
import './AboutUsHome.css';
import Image2 from './Assests/Image2.png'


function AboutUsHome()     // AboutUs section on home pg
{

 return (
    <section id="aboutus1" className='white'>
        
        <div className='Wrap2 flex-container'>
          <div className='col-11'> 
            <div className="headBox2">
                <h2 className='headline2'>ABOUT US</h2>
            </div>
          </div>
        
           <div className='desc'>
             <p>At Gargi Logistics, we take immense pride in being a leading logistics company specializing in land freight services or road transport. With a steadfast commitment to delivering excellence on roads, we have established ourselves as a trusted name in the industry. Our dedication to providing reliable, efficient, and cost-effective logistics solutions sets us apart, making us the preferred choice for businesses and individuals alike.
             </p>
           </div>
        
            <div className='imagedecor'>
                <img src={Image2}></img>

                <div className='button2'>
                <a href="#" className='text'>Know More</a>
                </div>
            </div>


        </div>

    </section>
  )
}
export default AboutUsHome;