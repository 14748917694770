import React, { useEffect} from 'react';
import './GetQuote.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Tracking1 from '../src/Assests/tracking1.png';
import Tracking2 from '../src/Assests/tracking2.png';
import Tracking3 from '../src/Assests/tracking3.png';

import Aos from "aos";
import 'aos/dist/aos.css'

const GetQuote = () => {

    useEffect(()=>{
        Aos.init({duration: 2000});
    },[]);

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log('Form Data:', form.current);
    emailjs.sendForm('service_zd8ffk4', 'template_kznlbwy', form.current, 'm3iNdQfHheBpJzR1o')
    
      .then((result) => {
          console.log(result.text);
          console.log('sendEmail function called');
  console.log('form.current:', form.current);
      }, (error) => {
          console.log(error.text);
      });

  };
  return (
    <section className='completeGet' >
        <div className='galleryheader-1'>
            <h1>SHIP YOUR PRODUCT WITH US</h1>
        </div>
        <div className='imagesli-1'>
            <ul>
                <li>
                    <img src={Tracking1}></img>
                    <h1 className='text-1'>Easy Tracking</h1>
                </li>
                <li>
                    <img src={Tracking2}></img>
                    <h1 className='text-1'>Door step delivery</h1>
                </li>
                <li>
                    <img src={Tracking3}></img>
                    <h1 className='text-1'>Fast and safe</h1>
                </li>
            </ul>
            
        </div>

        <form className='form-flex-1' ref={form} onSubmit={sendEmail}>
            <div className="formDiv">
            <label className='label-1' for="name">Name </label>
            <input type='name' className='flex-1' id="name" name='name' placeholder='Enter your name'></input><br></br>
            </div>
            
            <div className="formDiv">
            <label className='label-1' for="mobilenumber">Mobile number </label>
            <input type='mobilenumber' className='flex-2' id = "mobilenumber" name='mobilenumber' placeholder='Enter your number'></input><br></br>
            </div>

            <div className="formDiv">
            <label className='label-1' for="email">Email </label>
            <input type='email' className='flex-3' id="email" placeholder='Enter your email' name='email'></input><br></br>
            </div>

            <div className="formDiv">
        <div className='picDil'>
            <div className="picLoc">
            <label className='label-1' for="pickuplocation">Pickup location </label> <br></br>
            <input type='location' className='flex-4' id="pickuplocation" placeholder='Pickup location' name='pickuplocation'></input>
            </div>
            <div className="dropLoc">
            <label className='label-1' for="deliverylocation">Delivery location </label>
            <input type = 'location' className='flex-4-1' id="deliverylocation"  placeholder = 'delivery location' name='deliverylocation'></input><br></br>
            </div>
            </div>
        </div>
            
        <div className="formDiv">
            <label className='label-1' for="pickupdate" id="pickdt">Pickup date </label><br></br>
            <input type='date' className='flex-5' id="pickupdate" placeholder=""  name='pickupdate'></input><br></br>
            </div>
            <div className="formDiv">
            <label className='label-1' for="numberofboxes" id="boxes">Number of boxes </label><br></br>
            <input type='number' className='flex-6' id="numberofboxes" placeholder='Number of boxes' name='numberofboxes'></input><br></br>
            </div> 

            <div className="formDiv">
            <label className='label-1' id="wt">Weight </label><br></br>
            <input type='number' className='flex-7' id="weight" placeholder='Weight' name='weight'></input><br></br>
            </div>
            <div className="formDiv">
            <label className='label-1' for="description" id="des">Description </label><br></br>
            <input type='text' className='flex-8' id="description" placeholder='Description' name='Description'></input><br></br>
            </div>
            <div className="formDiv">
            <h1 >Submit</h1>
            <input id="text-submit" type='submit' ref={form} onSubmit={sendEmail}>
                
            </input>
            </div>
        </form>
    </section>
  )
}

export default GetQuote;