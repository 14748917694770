//design 11111111111111111111111111111111111111111111111111111111111111

// import React from 'react'
// import imagetruck from './Components/Imgesgallery/Aboutimg.png';
// import './About.css';
// function Abo() {
//   return (
//     <div className='abou'>
//         <h1>ABOUT US</h1>
      
//         <div className='twoside'>

//             <div id="d1">
//             <h3> Welcome to Gargi Logistics!</h3>
//             <br />
//             <div>
//             <p className="about1">
//               At Gargi Logistics, we take immense pride in being a leading logistics company specializing in land freight services or road transport. With a steadfast commitment to delivering excellence on roads, we have established ourselves as a trusted name in the industry. Our dedication to providing reliable, efficient, and cost-effective logistics solutions sets us apart, making us the preferred choice for businesses and individuals alike.
//             </p>
//             </div>
//             </div>
//             <div id="d2">    
//             <img src={imagetruck} />
//             </div>


//         </div>
//     </div>
//   )
// }

// export default Abo;


//design 222222222222222222222222222222222222222222222222222222222222222
// function About() {

//   // useEffect(()=>{
//   //    Aos.init({duration:2000});
//   //   },[]);


//    return (
//     <section>
//       <div className='Container' >
//         <h1>ABOUT US</h1>
        
//           <div className='twoside'>
//             <div id="d1">
//               <h3> Welcome to Gargi Logistics!</h3>
//               <div>
//                 <p className="about1">
//                   At Gargi Logistics, we take immense pride in being a leading logistics company specializing in land freight services or road transport. With a steadfast commitment to delivering excellence on roads, we have established ourselves as a trusted name in the industry. Our dedication to providing reliable, efficient, and cost-effective logistics solutions sets us apart, making us the preferred choice for businesses and individuals alike.
//                 </p>
//               </div>

//               </div>

//               <div id="d2">    
//               <img src={imagetruck} />
//             </div>


//           </div>

//       <div className="abMain" >
//           <div id="d3" className='Our-vision'>
//               <h3>Our Vision</h3>
//               <p>Our vision at Gargi Logistics is simple yet powerful: to be the most preferred road transport partner, catering to diverse industries and delivering unparalleled customer satisfaction. We envision a future where logistics become seamless, and businesses can focus on their core activities with confidence, knowing that their freight is in safe hands.</p></div>

//             <div className='why-gargi'>
//               <h3> Why Choose Gargi Logistics?</h3>
//               {/* <ul id="ul1"> */}
//               <p>
//               <b>Reliability:</b> When you choose Gargi Logistics, you can rest assured that your cargo will be handled with the utmost care and professionalism. Our experienced team, backed by state-of-the-art technology, ensures that your shipments reach their destination on time, every time.</p>

//               <p>
//               <b>Customer-Centric Approach:</b> Our customers are at the heart of everything we do. We tailor our logistics solutions to suit your unique requirements, providing personalized service that exceeds your expectations.</p>
//               <p>
//               <b>Comprehensive Network:</b> With an extensive network of routes and strategic partnerships, we have a wide reach across the region. Whether it's a local delivery or long-haul transportation, Gargi Logistics has got you covered.</p>

//               <p>
//               <b>Safety First:</b> Safety is our top priority. We adhere to the highest industry standards, implementing stringent safety protocols to protect your cargo throughout the journey.</p>
//               <p>
//               <b>Cost-Effectiveness:</b> We understand the importance of cost efficiency in logistics. By optimizing our processes and leveraging economies of scale, we offer competitive pricing without compromising on quality.</p>

//           </div>
//             <div className='Our-team'>
//               <h3> Our Team</h3>

//               <p>Our team comprises logistics experts, skilled drivers, and dedicated support staff who work harmoniously to streamline your transportation needs. Their expertise, combined with a passion for delivering excellence, ensures a seamless logistics experience for you.</p>

//               <ul>
//                 <li>Our Commitment to Sustainabilit</li>
//                 <li> Technological Advancements</li>
//                 <li>Customer Support</li>
//                 <li>Your Reliable Road Transport Partner</li>
//               </ul>
//             </div>
//           </div>

//           <div className='status'>

//             <img src={Container}></img>
//             <h1 className='Heading'>Our Status</h1>
//             <div className='logos'>
//               <ul className='listlogo'>
//                 <li>
//                   <img src={SmallLogo}></img>
//                 </li>
//                 <li>
//                   <img src={STeam}></img>
//                 </li>
//                 <li>
//                   <img src = {Cl}></img>
//                 </li>
//                 <li>
//                   <img src={City}></img>
//                 </li>
//               </ul>

//             </div>
//           </div>

//          <div className='grey-bg'>
//           <div class='items'>
//             <ul>
//               <li class='image1'>
//                 <img src = {Express}></img>
//               </li>
//               <li class='image2'>
//                 <img src={Ekart}></img>
//               </li>
//               <li class='image3'>
//                 <img src={Delhivery}></img>
//               </li>
//               <li class='image4'>
//                 <img src={Dtdc}></img>
//               </li>
//               <li class='image5'>
//                 <img src={SafeExpress}></img>
//               </li>
//               <li class='image1'>
//                 <img src = {Express}></img>
//               </li>
//               <li class='image2'>
//                 <img src={Ekart}></img>
//               </li>
//               <li class='image3'>
//                 <img src={Delhivery}></img>
//               </li>
//               <li class='image4'>
//                 <img src={Dtdc}></img>
//               </li>
//               <li class='image5'>
//                 <img src={SafeExpress}></img>
//               </li>
              
//             </ul>
//           </div>
//         </div>
//         </div>
//       </section>

//   )
// }

// export default About;

//design 333333333333333333333333333333333333333333333333333333333333333333333333333333333
import React from 'react'
import './Abo.css';
import Image2 from './Assests/Image2.png';
import Image3 from './Assests/Image3.png'
import Image4 from './Assests/Image4.png'
import Image5 from './Assests/Image5.png'

import Image2_1 from './Assests/Image2_1.png'
import Image2_2 from './Assests/Image2_2.png'
import Image2_3 from './Assests/Image2_3.png'
import Image2_4 from './Assests/Image2_4.png'

function About(){
  return(
  <div className='container'>
    <h1>ABOUT US</h1>
    <img src={Image4}></img>
    <div className='info'>
      <p>At Gargi Logistics, we take immense pride in being a leading logistics company specializing in land freight services or road transport. With a steadfast commitment to delivering excellence on roads, we have established ourselves as a trusted name in the industry. Our dedication to providing reliable, efficient, and cost-effective logistics solutions sets us apart, making us the preferred choice for businesses and individuals alike.</p>
    </div>

    <div className='boxx'>
      <div className='VisionIcon'>
        <img src={Image5}></img>
      </div>

      <div className='VisionContent'>
        <h1>Vision</h1>
        <br></br>
        <p>Our vision at Gargi Logistics is simple yet powerful: to be the most preferred road transport partner, catering to diverse industries and delivering unparalleled customer satisfaction. We envision a future where logistics become seamless, and businesses can focus on their core activities with confidence, knowing that their freight is in safe hands.
        </p>
      </div>
    </div>

    <div className='why' > Why Choose Gargi Logistics?</div>
    <img src={Image3}></img>


    <h3 className='teamHeading'>Our Team</h3>
    <div className='team'>
      <div className='circle1'>
        <div className='circle2'>
          <img src={Image2_1} ></img>
          <h5>Commitment</h5>
        </div>
      </div>

      <div className='circle1'>
        <div className='circle2'>
          <img src={Image2_2} ></img>
          <h5>Technological Advancements</h5>
        </div>
      </div>

      <div className='circle1'>
        <div className='circle2'>
          <img src={Image2_3} ></img>
          <h5>Customer support</h5>
        </div>
      </div>

      <div className='circle1'>
        <div className='circle2'>
          <img src={Image2_4} ></img>
          <h5>Reliable Road Transport Partner</h5>
        </div>
      </div>
    </div>

  </div>
  )
}

export default About