
import OurPartners from './OurPartners';

import Footer from './Footer';
import GetQuote from './GetQuote';
import ContactUs from './ContactUs';

import './styles.css';
import {NavBar} from './NavBar';
import Home from './Home';

import {BrowserRouter, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import FAQsMain from './FAQsMain';
import About from './Abo';
import Gal from './Gal';
import Our1 from './Our1';

function App()
{
  return (
    <div>
      
    
    <HashRouter>
       <NavBar/>
      <Routes>
      <Route path='/' element={<Home/>}/>
     
      <Route path='/GetQuote' element={<GetQuote/>}/>
      <Route path='/ContactUs' element={<ContactUs/>}/>
      <Route path='/AboutUs' element={<About/>}/>              {/* for AboutUs page */}
      <Route path='/Gallery' element={<Gal/>}/>
      <Route path='/services' element={<Our1/>}/>
      <Route path='/FAQs' element={<FAQsMain/>}/>
      </Routes>

    <Footer />
    </HashRouter>
      
    </div>
      
  );
}
export default App;