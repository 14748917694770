import React, {useEffect} from 'react';
import './Gal.css';
import image1 from './Components/Imgesgallery/Gallery1.png';
import image2 from './Components/Imgesgallery/Gallery2.png';
import image3 from './Components/Imgesgallery/Gallery3.png';
import image4 from './Components/Imgesgallery/Gallery4.png';
import image5 from './Components/Imgesgallery/Gallery5.png';
import image6 from './Components/Imgesgallery/Gallery6.png';

// import Aos from "aos";
// import 'aos/dist/aos.css'

function Gal() {

  // useEffect(()=>{
  //   Aos.init({duration: 2000});
  //  },[]);

  return (
    <div className='mainGal' >
      <h2 className="heading1" >GALLERY</h2>
      <div className="container">
        <span className="spancolor">Here are some images of our logistics.</span>
      </div>
      <div className="main-div">
        <div className="image-grid" >
          <div className='InnerGrid'>
          <img src={image1}/>
          </div>
          <div className='InnerGrid'>
          <img src={image2}/>
          </div>
          <div className='InnerGrid'>
          <img src={image3}/>
          </div>
        </div>
        <div className="image-grid">
          <div className='InnerGrid'>
           <img src={image4}/>
          </div>
          <div className='InnerGrid'>
          <img src={image5}/>
          </div>
          <div className='InnerGrid'>
          <img src={image6}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gal;
