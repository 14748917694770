import React from 'react'
import imaget from './Components/Imgesgallery/truck delivery 1.png';
import Our from './Our';
import './Our1.css';
import Circle from './Circle';

import Image10 from './Assests/Image10.png';
import Image11 from './Assests/Image11.png';
import Image12 from './Assests/Image12.png';
import Image13 from './Assests/Image13.png';

function Our1() {
  return (
    <div className='container'>
     <h6>OUR SERVICES</h6>
     <div className='mainB'>
      <div className='block'>
        <img src={Image10}></img>
        <div className='innerB'>
          <h4>Full Truck Load Service</h4>
          <p>For shipments that require an entire truck's capacity, our FTL service ensures direct and non-stop delivery to your destination.</p>
        </div>
      </div>

      <div className='block'>
        <img src={Image11}></img>
        <div className='innerB'>
          <h4>Less than Truck Load Service(LTL)</h4>
          <p>For smaller shipments, our LTL service allows you to share truck space with other customers, making it a cost-effective choice.</p>
        </div>
      </div>

      <div className='block'>
        <img src={Image12}></img>
        <div className='innerB'>
          <h4>Temperature Controlled Transport</h4>
          <p>We handle perishable goods and temperature-sensitive cargo with precision, maintaining the required conditions throughout transit.</p>
        </div>
      </div>

      <div className='block'>
        <img src={Image13}></img>
        <div className='innerB'>
          <h4>Express Deliveries</h4>
          <p>When time is of the essence, our express delivery service guarantees swift transportation, meeting urgent deadlines without compromise</p>
        </div>
      </div>
     </div>

     <Circle/>
    </div>
  )
}

export default Our1;