import React from "react";
import './LatestNewsBody.css';
function LatesNewsBody1()
{
    return (
        <div className="contentBody1">
            <div className="content">
            Gargi logistics get funded
            </div>
            <div className="newsDate">
                June 11,2023
            </div>
            <div className="readMore">
                <a href="#">Read More →</a>
            </div>
        </div>
    )
}

export default LatesNewsBody1;